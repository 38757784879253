<template>
  <div class="news">
    <el-container>
      <el-aside width="200px" style="padding-right: 8px;">
        <div class="xhgk">
          <div class="title">集团概况</div>
          <ul>
            <li><router-link tag="a" to="/news/jgjj">集团简介</router-link></li>
            <li><router-link tag="a" to="/news/zxdt">资讯动态</router-link></li>
            <li><router-link tag="a" to="/news/zcfg">政策法规</router-link></li>
            <li><router-link tag="a" to="/news/rzfw">认定服务</router-link></li>
            <li><router-link tag="a" to="/news/bzjd">标准研究</router-link></li>
            <li><router-link tag="a" to="/about/us">联系我们</router-link></li>
          </ul>
        </div>
        <div class="cl" style="height:5px"></div>
        <div class="container1">
          <div class="title">公告</div>
          <div class="con">
            <ul>
              <li class="list" v-for="(item , i ) in list">
                <a :href="'/news/info/'+item.id" :title="item.title">{{ item.title }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="cl" style="height:5px"></div>
      </el-aside>
      <el-main>
        <router-view/>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import news from "../../api/news";

export default {
  data() {
    return {
      activeIndex: this.$route.path,
      list: null,
      listQuery: {
        page: 1,
        limit: 6,
        order: { '`release`': 'desc' },
        where: {
          equals: {state: 1},
          likes: {},
        }
      }
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      news.newsList(this.listQuery).then(response => {
        this.data = response.data.data;
        this.list = this.data.list;
      });
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  }
}</script>
<style scoped>
.news {
  border: 1px solid #dadada;
  margin-top: 10px;
  background: #FFFFFF;
}

.el-container {
  margin: 8px;
  width: 974px;
}

.xhgk {
  background: url("~@/assets/images/index/xhgk-bg.png") #e6e9fb repeat-x;
  border: 1px solid #d3d8ee;
  border-top: none;
  text-align: center;
}

.xhgk .title {
  height: 26px;
  line-height: 26px;
  background: url("~@/assets/images/index/xhgk-title-bg.png")  no-repeat center;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 14px;
}

.xhgk ul {
  padding: 5px 0 5px 10px;
}

.xhgk ul li {
  background: url("~@/assets/images/index/xhgk-btn.png");
  width: 176px;
  line-height: 23px;
  margin: 5px 0;
}

.xhgk ul li a {
  background: url("~@/assets/images/index/xhgk-btn-jt.png") no-repeat 0 6px;
  padding-left: 14px;
  color: #38393a;
}

.container1 {
  border: 1px solid #d3d8ee;
  background: #f0f1fa;
}

.container1 .title {
  background: url("~@/assets/images/index/con1_title.png") no-repeat;
  height: 26px;
  line-height: 26px;
  font-size: 14px;
  color: #FFFFFF;
  font-weight: bold;
  padding-left: 5px;
}

.container1 .title a {
  color: #FFFFFF;
  font-size: 9px;
  margin-right: 10px;
  float: right;
}

.container1 .con {
  padding: 5px 3px 5px 8px;
  overflow: hidden;
}

.container1 .con .list {
  background: url("~@/assets/images/index/con1_list_jt.png") no-repeat 0 8px;
  margin: 5px 0;
  padding-left: 10px;
}

.container1 .con .list2 {
  width: 90px;
  margin: 3px 0;
  float: left;
}

.container1 .con .list a, .container1 .con .list2 a {
  text-decoration: none;
  color: #38393a;
}

.container2 {
  background: #fbfbfe;
  border: 1px solid #e2e5f1;
}

.container2 .title {
  border-bottom: 2px solid #4e83b9;
  height: 28px;
  line-height: 28px;
  position: relative;
  font-size: 14px;
  font-weight: bold;
  color: #285087;
  padding-left: 8px;
}

.container2 .title a {
  font-size: 9px;
  margin-right: 10px;
  float: right;
  color: #b80505;
}

.container2 .con {
  padding: 12px 10px 0px;
  padding: 13px 10px 0px \9;
}

.container2 .con li {
  font-size: 12px;
}

.container2 .con .list {
  background: url("~@/assets/images/index/con1_list_jt.png") no-repeat 0 8px;
  margin: 5px 0;
  padding-left: 10px;
  line-height: 17px
}

.container2 .con .list a {
  text-decoration: none;
  color: #38393a;
}

.xhxw, .xhtz, .dwjl, .bzhxx, .bzhzc, .bzhzs {
  float: left;
  width: 273px;
  height: 181px;
  margin-top: 10px;
}

.bzhxx, .bzhzc, .bzhzs {
  float: right;
}

.fwrx {
  font-size: 14px;
  color: #406da6;
  text-align: center;
  font-weight: bold;
  margin-bottom: 2px;
}

.link {
  margin-top: 10px;
  background: #f0f1fa;
  border: 1px solid #d5daef;
  height: 35px;
  line-height: 35px;
}

.link li {
  float: left;
  font-size: 12px;
  margin: 0 10px;
}

.link li b {
  color: #b80505;
}

.link li a {
  text-decoration: none;
  color: #3c3c3c;
}

.link .zgdw {
  float: left;
  width: 320px;
  border-right: 1px dashed #d8dae5;
  margin: 0 12px 0 20px;
  _margin-left: 0;
}

.link .dfbx {
  float: left;
  width: 150px;
  border-right: 1px dashed #d8dae5;
  margin: 0 12px;
}

.link .gjzz {
  float: left;
  width: 220px;
  margin: 0 12px;
}
</style>
