<template>
  <div class="contact">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>联系我们</el-breadcrumb-item>
    </el-breadcrumb>
    <div style="margin: 10px 0px;height:550px;border: 1px solid #dddddd;">
      <el-row style="margin: 10px 10px 20px;">
        <el-col :span="24" style="text-align: left;">
          <span style="margin-left: 10px;font-size:18px;color:#d80c18;">联系我们</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="13">
          <div id="l-map" style="margin-left:10px;margin-right: 10px; height:300px;border:#ccc solid 1px;">
            <iframe id="show-iframe"  frameborder=0 name="showHere" width="500" height="300" scrolling=no src="/map.html"></iframe>
          </div>
        </el-col>
        <el-col :span="11" style="padding-left: 20px;text-align: left;">
          <p style="margin: 10px auto; font-size: 18px;">
            中关村新兴科技服务业产业联盟
          </p>
          <div style="font-size: 14px;line-height: 1.75;"><span>办公地址：北京市朝阳区电子城创新产业园M8楼C厅601</span></div>
          <div style="font-size: 14px;line-height: 1.75;"><span>邮编：100015</span></div>
          <div style="font-size: 14px;line-height: 1.75;"><span>秘书处电话：010-64330302</span></div>
          <div style="font-size: 14px;line-height: 1.75;">
            <div style="float: left;width: 45%;">
              <span>微信公众号：stsizpark</span>
              <img src="//nwzimg.wezhan.cn/contents/sitefiles2019/10099303/images/2158921.png" alt="微信公众号" title="微信公众号"
                   style="width: 129px; height:127px;"/></div>
            <div style="float: left;width: 45%;">
              <span>QQ服务群：</span>
              <img src="//nwzimg.wezhan.cn/contents/sitefiles2019/10099303/images/2158920.png" alt="QQ服务群" title="QQ服务群"
                   id="img_smv_con_34_29" style="width: 117px; height: 154px;"/>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    /*var map = new BMap.Map("l-map");
    var point = new BMap.Point(116.35742, 39.981461);//定义一个中心点坐标
    map.centerAndZoom(point, 18);//设定地图的中心点和坐标并将地图显示在地图容器中
    map.enableDragging();//启用地图拖拽事件，默认启用(可不写)
    map.enableScrollWheelZoom();//启用地图滚轮放大缩小
    map.enableDoubleClickZoom();//启用鼠标双击放大，默认启用(可不写)
    map.enableKeyboard();//启用键盘上下左右键移动地图

    //向地图中添加缩放控件
    var ctrl_nav = new BMap.NavigationControl({anchor: BMAP_ANCHOR_TOP_LEFT, type: BMAP_NAVIGATION_CONTROL_LARGE});
    map.addControl(ctrl_nav);
    //向地图中添加缩略图控件
    var ctrl_ove = new BMap.OverviewMapControl({anchor: BMAP_ANCHOR_BOTTOM_RIGHT, isOpen: 1});
    map.addControl(ctrl_ove);
    //向地图中添加比例尺控件
    var ctrl_sca = new BMap.ScaleControl({anchor: BMAP_ANCHOR_BOTTOM_LEFT});
    map.addControl(ctrl_sca);

    var markerJson = {
      title: "中国标准化研究院",
      content: "中国标准化研究院",
      point: [116.357286, 39.981585],
      isOpen: 0,
      icon: {w: 23, h: 25, l: 46, t: 21, x: 9, lb: 12}
    };
    var iconImg = new BMap.Icon("http://app.baidu.com/map/images/us_mk_icon.png", new BMap.Size(markerJson.icon.w, markerJson.icon.h), {
      imageOffset: new BMap.Size(-markerJson.icon.l, -markerJson.icon.t),
      infoWindowOffset: new BMap.Size(markerJson.icon.lb + 5, 1),
      offset: new BMap.Size(markerJson.icon.x, markerJson.icon.h)
    })

    var point = new BMap.Point(markerJson.point[0], markerJson.point[1]);
    var marker = new BMap.Marker(point, {icon: iconImg});

    var iw = new BMap.InfoWindow("<b class='iw_poi_title' title='" + markerJson.title + "'>" + markerJson.title + "</b><div class='iw_poi_content'>" + markerJson.content + "</div>");
    var label = new BMap.Label(markerJson.title, {"offset": new BMap.Size(markerJson.icon.lb - markerJson.icon.x + 10, -20)});
    marker.setLabel(label);
    map.addOverlay(marker);
    label.setStyle({
      borderColor: "#808080",
      color: "#333",
      cursor: "pointer"
    });
*/
  },
}
</script>
<style scoped>
.contact {
  margin-top: 10px;
}
</style>