<template>
  <div class="xwzx">
    <div class="tzgg_bt">
      <b>{{ translateCategory(category) }}</b>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="f-r pr-10 dqwz2">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ translateCategory(category) }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="news-list">
      <ul>
        <li v-for="(item , i ) in list">
          <a href="javascript:void(0)"
             v-on:click="showNews(item.id)">{{ item.title }}</a><span>{{ item.release }}</span>
        </li>
      </ul>
      <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
                  @pagination="fetchData"/>
    </div>
  </div>
</template>
<script>
import news from "../../api/news";
import Pagination from '@/components/Pagination'

export default {
  name: 'XWZX',
  components: {Pagination},
  data() {
    return {
      total: 0,
      category: '',
      data: null,
      list: null,
      listQuery: {
        page: 1,
        limit: 10,
        order: { '`release`': 'desc' },
        where: {
          equals: {state: 1},
          likes: {},
        }
      }
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
    '$route': {
      handler(newVal, oldVal) {
        this.fetchData()
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    showNews(id) {
      this.$router.push({path: "./info/" + id})
    },
    translateCategory(key) {
      return !key ? '资讯动态' : this.Common.categoryTypes[key].label;
    },
    fetchData() {
      let path = this.$route.path;
      let category = path.substring(path.lastIndexOf('/') + 1).toUpperCase();
      this.category = category;
      this.listQuery.where.equals.category = category;

      news.newsList(this.listQuery).then(response => {
        this.data = response.data.data;
        this.total = this.data.total;
        this.list = this.data.list;
      });
    }
  }
}</script>
<style scoped>
.xwzx {
  border: 1px solid #DADADA;
}

.tzgg_bt {
  width: 100%;
  border-bottom: 2px solid #E6E6E6;
  height: 26px;
  line-height: 26px;
}

.tzgg_bt b {
  padding-left: 10px;
  color: #6A6A6A;
}

.dqwz2 {
  font-size: 12px;
  color: #5D5D5D;
  line-height: 27px;
}

.news-list {
  min-height: 600px;
  padding: 10px;
}

.news-list p {
  text-align: left;
  font-size: 18px;
  color: #D80C18;
}

.news-list ul li {
  text-align: left;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  border-bottom: 1px dotted #CCC;
  padding-left: 17px;
  color: #666;
  background: url("~@/assets/images/news/list-icon.png") no-repeat 0 13px;
}

.news-list ul li a {
  font-weight: bold;
  color: #666;
}


.news-list ul li span {
  float: right;
  color: #BBB;
}
</style>
