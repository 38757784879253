import Vue from 'vue'
import VueRouter from 'vue-router'

import Index from '@/views/Index'

import About from '@/views/about/About'
import us from '@/views/about/us'
import lmzc from '@/views/about/lmzc'
import zzjg from '@/views/about/zzjg'
import cxjs from '@/views/about/cxjs'

import News from "@/views/news/News";
import xwzx from "@/views/news/xwzx";
import tzgg from "@/views/news/tzgg";
import zxzc from "@/views/news/zxzc";
import cjwt from "@/views/news/cjwt";
import NewsList from '@/views/news/List'
import NewsInfo from '@/views/news/Info'

import Center from '@/views/center/Center'

import Contact from '@/views/contact/Contact'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'Index',
    component: Index,
    meta: {title: '首页'}
  }, {
    path: '/about',
    name: 'About',
    component: About,
    meta: {title: '关于我们'},
    children: [
      {path: 'us', name: 'us', component: us},
      {path: 'lmzc', name: 'lmzc', component: lmzc},
      {path: 'zzjg', name: 'zzjg', component: zzjg},
      {path: 'cxjs', name: 'cxjs', component: cxjs},
    ]
  }, {
    path: '/center',
    name: 'Center',
    component: Center
  }, {
    path: '/contact',
    name: 'Contact',
    component: Contact
  }, {
    path: '/news',
    name: 'News',
    component: News,
    meta: {title: '新闻动态'},
    children: [
      {path: 'jgjj', name: '集团简介', component: NewsList, meta: {title: '集团简介'}},
      {path: 'zxdt', name: '资讯动态', component: NewsList, meta: {title: '资讯动态'}},
      {path: 'zcfg', name: '政策法规', component: NewsList, meta: {title: '政策法规'}},
      {path: 'jsfw', name: '技术服务', component: NewsList, meta: {title: '技术服务'}},
      {path: 'xxgk', name: '信息公开', component: NewsList, meta: {title: '信息公开'}},
      {path: 'jypx', name: '教育培训', component: NewsList, meta: {title: '教育培训'}},
      {path: 'hyfw', name: '会员服务', component: NewsList, meta: {title: '会员服务'}},
      {path: 'bzcx', name: '标准查询', component: NewsList, meta: {title: '标准查询'}},
      {path: 'rzfw', name: '认定服务', component: NewsList, meta: {title: '认证服务'}},
      {path: 'bzkp', name: '标准科普', component: NewsList, meta: {title: '标准科普'}},
      {path: 'bzjd', name: '标准研究', component: NewsList, meta: {title: '标准研究'}},
    ]
  }, {
    path: '/news/list',
    name: 'NewsList',
    component: NewsList
  }, {
    path: '/news/info/:id',
    name: 'NewsInfo',
    component: NewsInfo
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
