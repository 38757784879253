/**
 * 新闻模块接口列表
 */

import base from './base'; // 导入接口域名列表
import axios from './http'; // 导入http中创建的axios实例

const news = {
  // 新闻列表
  newsList(params) {
    return axios.post(`${base.api}/news/list`,
      params,
      {header: {'Content-Type': 'application/json'}}
    );
  },
  // 新闻详情
  newsDetail(id) {
    return axios.get(`${base.api}/news/info?id=${id}`);
  },
}

export default news;