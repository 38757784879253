<template>
  <div id="navMenu" class="nav-menu">
    <ul>
      <template v-for="(item,i) in navMenus">
        <li>
          <router-link tag="a" :to="item.index">{{ item.name }}</router-link>
        </li>
        <li v-if="i!==navMenus.length-1">|</li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'NavMenu',
  props: ['navMenus'],
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
</style>
