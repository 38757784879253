<template>
  <div class="news-info" id="news-info">
    <div class="news-info-title">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="f-r pr-10 f-12 lh-28">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:translateCategory2(data.category)}">{{ translateCategory(data.category) }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ data.title }}</el-breadcrumb-item>
      </el-breadcrumb>
      <!--        <p>{{ data.title }}</p>
              <span>创建时间：{{ data.release }}</span><span style="margin-left: 10px;">浏览量：{{ data.count }}</span>-->
    </div>
    <div class="news-info-content">
      <div class="news-info-content-1">
        <div class="news-info-content-2">
          <div class="news-info-content-nav">
            <h4 style="text-align: center;color: #315990;">{{ data.title }}</h4>
            <div class="huabian"></div>
            <div style="font-size:12px; margin-top:5px; text-align:center; color:#B0B0B0;">
              <span>发布时间：{{ data.release }}</span><span class="ml-20">浏览量：{{ data.count }}</span>
            </div>
          </div>
          <div class="news-info-content-con" v-html="data.content"></div>
          <table>
            <tr v-for="(item, index) in data.fileLinkList">
              <td>附件{{ index + 1 }}：</td>
              <td><a href="javascript:void(0)" v-on:click="download(item.fid)">{{ item.name }}</a></td>
            </tr>
          </table>
          <div><a :href="data.v8" :target="data.v7 === 2 ? `_blank` : `_self`"></a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import news from "../../api/news";

export default {
  name: "NewsInfo",
  data() {
    return {
      data: {
        id: "",
        created: "",
        updated: "",
        category: "",
        category2: "",
        title: "",
        state: 0,
        count: 0,
        release: "",
        content: "",
        fileLinkList: []
      },
      id: this.$route.params.id
    };
  },
  created() {
    news.newsDetail(this.id).then(res => {
      this.data = res.data.data;
      if (this.data.v7 && this.data.v7 > 0) {
        window.open(this.data.v8, this.data.v7 === '2' ? '_blank' : '_self');
      }
    });
  },
  methods: {
    download(id) {
      window.open('/api/file/down?id=' + id);
    },
    translateCategory(category) {
      return !category ? '资讯动态' : this.Common.categoryTypes[category].label;
    },
    translateCategory2(category) {
      return '/news/' + (!category ? 'zxdt' : this.Common.categoryTypes[category].value.toLowerCase());
    }
  }
}
</script>

<style scoped>
.news-info {
  margin: 5px 0px;
  background: url("~@/assets/images/news/info-bg.png") repeat-x;
  background: #fff;
  border: 1px solid #dadada;
}

.news-info-content {
  margin: 5px 0px;
}

.news-info-content-1 {
  width: 935px;
  margin: 0 auto;
  margin-top: 10px;
}

.news-info-content-2 {
  width: 915px;
  padding: 10px;
  border-color: #E6E6E6;
  border-style: solid;
  border-width: 1px 3px 3px 1px;
  margin-bottom: 10px;
}

.news-info-content-con {
  padding: 21px;
  color: #3A3A3A;
  margin: 0 auto;
}

.news-info-title {
  height: 27px;
  background: url("~@/assets/images/news/header-bg.png") repeat-x;
  border-bottom: 2px solid #E6E6E6;
  font-size: 12px;
}

.huabian {
  height: 5px;
  width: 905px;
  background: url("~@/assets/images/news/huabian.gif") repeat-x;
  margin-top: 10px;
}
</style>
