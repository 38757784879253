<template>
  <div class="about">
    <el-container>
      <el-aside width="220px">
        <div style="text-align: left; margin-left: 10px;">
          <span style="font-size:18px;color: #d80c18;">关于我们</span>
        </div>
        <el-menu :default-active="activeIndex" class="el-menu-nav2" text-color="#000" style="margin-top: 10px" router>
          <el-menu-item index="/about/us">关于我们</el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view/>
      </el-main>
    </el-container>
  </div>
</template>
<script>

export default {
  data() {
    return {
      activeIndex: this.$route.path,
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  }
}</script>
<style scoped>
.about {
  border: 1px solid #dadada;
  margin-top: 10px;
  background: #FFFFFF;
}
.el-container{
  margin: 10px;
  width: auto;
}
.el-menu-nav2 {
  margin-right: 10px;
}

.el-menu-nav2 > .el-menu-item {
  height: 33px;
  line-height: 33px;
  font-size: 14px;
  font-weight: normal;
  background-color: #EEEEEE;
}

.el-menu-nav2 > .el-menu-item:not(.is-disabled):focus, .el-menu-nav2 > .el-menu-item:not(.is-disabled):hover, .el-menu-nav2 > .el-submenu .el-submenu__title:hover {
  background-color: rgb(215, 12, 25);
}

.el-menu-nav2 .el-menu-item:not(.is-disabled):focus, .el-menu-nav2 .el-menu-item:not(.is-disabled):hover {
  color: #FFF;
}

.el-menu-nav2 > .el-menu-item.is-active {
  color: #FFF;
  background-color: rgb(215, 12, 25);
  border-bottom: none;
}
</style>
