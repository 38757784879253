<template>

</template>

<script>
const categoryArr = [
  {value: 'JGJJ', label: '集团简介'},
  {value: 'ZXDT', label: '资讯动态'},
  {value: 'ZCFG', label: '政策法规'},
  {value: 'JSFW', label: '技术服务'},
  {value: 'XXGK', label: '信息公开'},
  {value: 'JYPX', label: '教育培训'},
  {value: 'HYFW', label: '会员服务'},
  {value: 'BZCX', label: '标准查询'},
  {value: 'RZFW', label: '认定服务'},
  {value: 'BZKP', label: '标准科普'},
  {value: 'BZJD', label: '标准研究'}
];

const categoryTypes = {
  JGJJ: {value: 'JGJJ', label: '集团简介'},
  ZXDT: {value: 'ZXDT', label: '资讯动态'},
  ZCFG: {value: 'ZCFG', label: '政策法规'},
  JSFW: {value: 'JSFW', label: '技术服务'},
  XXGK: {value: 'XXGK', label: '信息公开'},
  JYPX: {value: 'JYPX', label: '教育培训'},
  HYFW: {value: 'HYFW', label: '会员服务'},
  BZCX: {value: 'BZCX', label: '标准查询'},
  RZFW: {value: 'RZFW', label: '认定服务'},
  BZKP: {value: 'BZKP', label: '标准科普'},
  BZJD: {value: 'BZJD', label: '标准研究'}
}

export default {
  categoryArr, categoryTypes
}
</script>

<style scoped>

</style>
