<template>
  <div class="index">
    <el-row>
      <el-col :span="5" style="padding-right: 8px;display: none;">
        <div class="xhgk">
          <div class="title"><img src="~@/assets/images/index/xhgk.png"></div>
          <ul>
            <li>
              <router-link tag="a" to="/about/us">集团简介</router-link>
            </li>
            <li>
              <router-link tag="a" to="/news/zxdt">资讯动态</router-link>
            </li>
            <li>
              <router-link tag="a" to="/news/zcfg">政策法规</router-link>
            </li>
            <li>
              <router-link tag="a" to="/news/rzfw">认定服务</router-link>
            </li>
            <li>
              <router-link tag="a" to="/news/bzjd">标准研究</router-link>
            </li>
          </ul>
        </div>
        <div class="cl" style="height:5px"></div>
        <div class="container1 hide">
          <div class="title">承担标准化技术委员会</div>
          <div class="con">
            <ul>
              <li class="list"><a href="/jsfwldfh/index.jhtml">全国雷电防护标准化技术委员会</a></li>
              <li class="list"><a href="/jsfwjjs/index.jhtml">全国洁净室及相关受控环境标准化技术委员会</a></li>
              <li class="list"><a href="/bzypgzb/index.jhtml">全国标准样品技术委员会</a></li>
              <li class="list"><a href="http://www.china-cas.org/xiangmuwyhjj.jhtml">全国项目管理标准化技术委员会</a></li>
              <li class="list"><a href="/jsfw3/index.jhtml">全国原产地域产品标准化工作组</a></li>
            </ul>
          </div>
        </div>
        <div class="cl" style="height:5px"></div>
        <!--        <div class="container1 hide">
                  <div class="title">
                    秘书处
                  </div>
                  <div class="con">
                    <ul>
                      <li class="list2"><a href="/mscxzbgs.jhtml">行政办公室</a></li>
                      <li class="list2"><a href="/mscjsfzb.jhtml">技术发展部</a></li>
                      <li class="list2"><a href="/mscbzypgzb.jhtml">标准样品工作部</a></li>
                      <li class="list2"><a href="/mscjypxb.jhtml">教育培训部</a></li>
                      <li class="list2"><a href="/msccwb.jhtml">财务部</a></li>
                      <li class="list2"><a href="/mschygljxmhzb.jhtml">会员管理及项目合作部</a></li>
                      <li class="list2"><a href="/msctuantibz.jhtml">团体标准工作部</a></li>
                    </ul>
                  </div>
                </div>
                <div class="cl" style="height:5px"></div>
                <div class="container1 hide">
                  <div class="title">二级机构</div>
                  <div class="con">
                    <ul>
                      <li class="list"><a href="/fybzrzjtyxgs.jhtml?#165">方圆标志认证集团有限公司</a></li>
                      <li class="list"><a href="/zgbzhzzs.jhtml?#166">中国标准化杂志社</a></li>
                      <li class="list"><a href="/hbwykjfzyxgs.jhtml?#167">华标伟业科技发展有限公司</a></li>
                    </ul>
                  </div>
                </div>
                <div class="cl" style="height:5px"></div>
                <div class="container1 hide">
                  <div class="title">分支机构
                    <router-link tag="a" to="/">更多>></router-link>
                  </div>
                  <div class="con">
                    <ul>
                      <li class="list2"><a href="/dianqidzfh.jhtml?#410"> 电器电子分会 </a></li>
                      <li class="list2"><a href="/dianxiandl.jhtml?#443"> 电线电缆委员会 </a></li>
                      <li class="list2"><a href="/fhqy.jhtml?#204"> 海洋分会 </a></li>
                      <li class="list2"><a href="/chengshijj.jhtml?#423"> 城市家具分会 </a></li>
                      <li class="list2"><a href="/fwmy.jhtml?#416"> 服务贸易分会 </a></li>
                      <li class="list2"><a href="/chengzhenjcss.jhtml?#424"> 城镇基础设施... </a></li>
                    </ul>
                  </div>
                </div>-->
      </el-col>
      <el-col :span="19" style="padding:0px 8px">
        <el-carousel height="219px">
          <el-carousel-item v-for="item in img_list">
            <img ref="img" :src="item.url" :alt="item.label"/>
          </el-carousel-item>
        </el-carousel>
        <el-row>
          <el-col :span="12">
            <div class="container2 xhxw">
              <div class="title">资讯动态<a href="/news/zxdt">更多>></a></div>
              <div class="con">
                <ul>
                  <li class="list" v-for="item in data.zxdt">
                    <a :href="'/news/info/'+item.id" :title="item.title" target="_blank"><span
                        class="title-date">{{ dateFormat(item.release) }}</span>
                      <span class="li-title">{{ item.title }}</span>
                    </a>
                  </li><!--style="color:#FF0000"-->
                </ul>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="container2 bzhxx">
              <div class="title">政策法规<a href="/news/zcfg">更多>></a></div>
              <div class="con">
                <ul>
                  <li class="list" v-for="item in data.zcfg">
                    <a :href="'/news/info/'+item.id" :title="item.title" target="_blank"><span
                        class="title-date">{{ dateFormat(item.release) }}</span>
                      <span class="li-title">{{ item.title }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row style="display: none;">
          <el-col :span="12">
            <div class="container2 xhtz">
              <div class="title">技术服务<a href="/news/jsfw">更多>></a></div>
              <div class="con">
                <ul>
                  <li class="list" v-for="item in data.jsfw">
                    <a :href="'/news/info/'+item.id" :title="item.title" target="_blank"><span
                        class="title-date">{{ dateFormat(item.release) }}</span>
                      <span class="li-title">{{ item.title }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="container2 bzhzc">
              <div class="title">教育培训<a href="/news/jypx">更多>></a></div>
              <div class="con">
                <ul>
                  <li class="list" v-for="item in data.jypx">
                    <a :href="'/news/info/'+item.id" :title="item.title" target="_blank"><span
                        class="title-date">{{ dateFormat(item.release) }}</span>
                      <span class="li-title">{{ item.title }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <div class="container2 dwjl" style="height: 205px;">
              <div class="title">认定服务<a href="/news/rzfw">更多>></a></div>
              <div class="con">
                <el-tabs type="card">
                  <el-tab-pane label="资讯">
                    <ul>
                      <li class="list" v-for="item in data.rzfw_zx">
                        <a :href="'/news/info/'+item.id" :title="item.title" target="_blank"><span
                            class="title-date">{{ dateFormat(item.release) }}</span>
                          <span class="li-title">{{ item.title }}</span>
                        </a>
                      </li>
                    </ul>
                  </el-tab-pane>
                  <el-tab-pane label="公告">
                    <ul>
                      <li class="list" v-for="item in data.rzfw_gg">
                        <a :href="'/news/info/'+item.id" :title="item.title" target="_blank"><span
                            class="title-date">{{ dateFormat(item.release) }}</span>
                          <span class="li-title">{{ item.title }}</span>
                        </a>
                      </li>
                    </ul>
                  </el-tab-pane>
                  <el-tab-pane label="证书">
                    <ul>
                      <li class="list" v-for="item in data.rzfw_zscx">
                        <a :href="'/news/info/'+item.id" :title="item.title" target="_blank"><span
                            class="title-date">{{ dateFormat(item.release) }}</span>
                          <span class="li-title">{{ item.title }}</span>
                        </a>
                      </li>
                    </ul>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="container2 bzhzs" style="height: 205px;">
              <div class="title">标准研究<a href="/news/bzjd">更多>></a></div>
              <div class="con">
                <el-tabs type="card">
                  <el-tab-pane label="标准公告">
                    <ul>
                      <li class="list" v-for="item in data.bzjd_bzgg">
                        <a :href="'/news/info/'+item.id" :title="item.title" target="_blank"><span
                            class="title-date">{{ dateFormat(item.release) }}</span>
                          <span class="li-title">{{ item.title }}</span>
                        </a>
                      </li>
                    </ul>
                  </el-tab-pane>
                  <el-tab-pane label="征求意见">
                    <ul>
                      <li class="list" v-for="item in data.bzjd_zqyj">
                        <a :href="'/news/info/'+item.id" :title="item.title" target="_blank">
                          <span class="title-date">{{ dateFormat(item.release) }}</span>
                          <span class="li-title">{{ item.title }}</span>
                        </a>
                      </li>
                    </ul>
                  </el-tab-pane>
                  <el-tab-pane label="标准立项">
                    <ul>
                      <li class="list" v-for="item in data.bzjd_bzlx">
                        <a :href="'/news/info/'+item.id" :title="item.title" target="_blank">
                          <span class="title-date">{{ dateFormat(item.release) }}</span>
                          <span class="li-title">{{ item.title }}</span>
                        </a>
                      </li>
                    </ul>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="5">
        <div class="container1">
          <div class="title" style="text-align: center;">联系我们</div>
          <div class="con" style="text-align: center;">
            <a href="#">
              <img src="~@/assets/images/index/qrcode.png" width="145" style="margin: 8px 4px;border: none;">
            </a>
            <!--            <div class="fwrx">联系人：戚女士</div>-->
            <div class="fwrx">联系电话：010-53380055</div>
            <div class="fwrx"><span style="margin-left: 70px;">010-53380077</span></div>
            <div class="fwrx">Email：stscz@stscz.com</div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row class="hide">
      <el-col :span="19" style="padding-right: 8px;">
        <div class="link">
          <div class="zgdw">
            <ul>
              <li><b>主管单位</b></li>
              <li><a href="http://www.samr.gov.cn/" target="_blank">市场监管总局</a></li>
              <li><a href="http://www.sac.gov.cn/" target="_blank">国家标准委</a></li>
              <li><a href="http://www.cast.org.cn" target="_blank">中国科协</a></li>
            </ul>
          </div>
          <div class="dfbx">
            <ul>
              <li><b>地方标协</b></li>
              <li><a href="http://" target="_blank">省标协</a></li>
            </ul>
          </div>
          <div class="gjzz">
            <ul>
              <li><b>国际组织</b></li>
              <li><a href="http://www.iso.org/iso/home.html" target="_blank">ISO</a></li>
              <li><a href="http://www.astm.org/" target="_blank">ASTM</a></li>
              <li><a href="http://www.din.de/cmd?level=tpl-home&amp;contextid=din" target="_blank">DIN</a></li>
            </ul>
          </div>
        </div>
      </el-col>
      <el-col :span="5"></el-col>
    </el-row>
  </div>
</template>

<script>
import index from "../api/index";

export default {
  name: "Index",
  data() {
    return {
      data: {},
      img_list: [
        {url: "./static/images/home/banner1.png", label: "$"},
        {url: "./static/images/home/banner2.png", label: "#"}
      ],
    }
  },
  methods: {
    fetchData() {
      index.indexList().then(response => {
        this.data = response.data.data;
      });
    },
    dateFormat(dt) {
      return dt.substring(5, 10);
    }
  },
  created() {
    this.fetchData();
  },
  mounted() {

  }
};
</script>
<style scoped>
.index {
  margin: 5px 0px;
  background: #FFFFFF;
}

.xhgk {
  background: url("~@/assets/images/index/xhgk-bg.png") #e6e9fb repeat-x;
  border: 1px solid #d3d8ee;
  border-top: none;
  text-align: center;
}

.xhgk .title {
  height: 26px;
}

.xhgk ul {
  padding: 5px 0 5px 10px;
}

.xhgk ul li {
  background: url("~@/assets/images/index/xhgk-btn.png");
  width: 176px;
  line-height: 23px;
  margin: 5px 0;
}

.xhgk ul li a {
  background: url("~@/assets/images/index/xhgk-btn-jt.png") no-repeat 0 6px;
  padding-left: 14px;
  color: #38393a;
}

.container1 {
  border: 1px solid #d3d8ee;
  background: #f0f1fa;
}

.container1 .title {
  background: url("~@/assets/images/index/con1_title.png") no-repeat center;
  height: 26px;
  line-height: 26px;
  font-size: 14px;
  color: #FFFFFF;
  font-weight: bold;
  padding-left: 5px;
}

.container1 .title a {
  color: #FFFFFF;
  font-size: 9px;
  margin-right: 10px;
  float: right;
}

.container1 .con {
  padding: 5px 3px 5px 8px;
  overflow: hidden;
}

.container1 .con .list {
  background: url("~@/assets/images/index/con1_list_jt.png") no-repeat 0 8px;
  margin: 5px 0;
  padding-left: 10px;
}

.container1 .con .list2 {
  width: 90px;
  margin: 3px 0;
  float: left;
}

.container1 .con .list a, .container1 .con .list2 a {
  text-decoration: none;
  color: #38393a;
}

.container2 {
  background: #fbfbfe;
  border: 1px solid #e2e5f1;
}

.container2 .title {
  border-bottom: 2px solid #4e83b9;
  height: 28px;
  line-height: 28px;
  position: relative;
  font-size: 14px;
  font-weight: bold;
  color: #285087;
  padding-left: 8px;
}

.container2 .title a {
  font-size: 9px;
  margin-right: 10px;
  float: right;
  color: #b80505;
}

.container2 .con {
  padding: 12px 10px 0px;
  padding: 13px 10px 0px \9;
}

.container2 .con li {
  font-size: 12px;
}

.container2 .con li.list {
  background: url("~@/assets/images/index/con1_list_jt.png") no-repeat 0 8px;
  margin: 5px 0;
  padding-left: 10px;
  line-height: 17px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.container2 .con li.list a {
  text-decoration: none;
  color: #38393a;
}

.title-date {
  float: right;
}

.xhxw, .xhtz, .dwjl, .bzhxx, .bzhzc, .bzhzs {
  float: left;
  width: 380px;
  height: 181px;
  margin-top: 10px;
}

.bzhxx, .bzhzc, .bzhzs {
  float: right;
}

.fwrx {
  font-size: 14px;
  color: #406da6;
  text-align: left;
  font-weight: bold;
  margin-bottom: 2px;
}

.link {
  margin-top: 10px;
  background: #f0f1fa;
  border: 1px solid #d5daef;
  height: 35px;
  line-height: 35px;
}

.link li {
  float: left;
  font-size: 12px;
  margin: 0 10px;
}

.link li b {
  color: #b80505;
}

.link li a {
  text-decoration: none;
  color: #3c3c3c;
}

.link .zgdw {
  float: left;
  width: 320px;
  border-right: 1px dashed #d8dae5;
  margin: 0 12px 0 20px;
  _margin-left: 0;
}

.link .dfbx {
  float: left;
  width: 150px;
  border-right: 1px dashed #d8dae5;
  margin: 0 12px;
}

.link .gjzz {
  float: left;
  width: 220px;
  margin: 0 12px;
}

.dwjl > .con {
  padding: 5px 10px 0px;
}
</style>
