<template>
  <div id="app">
    <div id="main_body" :style="cssMainBody">
      <el-container>
        <el-header height="127px">
          <div class="logo"></div>
          <div class="home">
            <a style="cursor: pointer;" v-on:click="setHome(this, 'http://www.stscz.com')">设为首页</a>
            | <a v-on:click="addFavo('http://www.stscz.com','中科标准研究院')" style="cursor: pointer;"
                 title="中科标准研究院">加入收藏</a>
          </div>
          <div class="tel">联系我们：<strong>010-53380055 010-53380077</strong></div>
        </el-header>
        <NavMenu :nav-menus="navList"></NavMenu>
        <el-main>
          <div id="Content" class="el-content">
            <router-view/>
          </div>
        </el-main>
        <el-footer>
          <span>Copyright © 2020 &nbsp; 北京中科标准科学技术研究院（有限合伙）</span>
          <span class="beian"><a href="https://beian.miit.gov.cn/" target="_blank">京ICP备20030247号</a></span>
          <span>地址：西城区阜外大街乙22号</span>
        </el-footer>
      </el-container>
    </div>
  </div>
</template>

<script>
import NavMenu from "@/components/NavMenu";

export default {
  components: {
    NavMenu
  },
  data() {
    return {
      activeIndex: this.$route.path,
      navList: [
        {index: '/', name: '首页'},
        {index: '/news/jgjj', name: '集团简介'},
        {index: '/news/zxdt', name: '资讯动态'},
        {index: '/news/zcfg', name: '政策法规'},
        //{index: '/news/jsfw', name: '技术服务'},
        //{index: '/news/xxgk', name: '信息公开'},
        //{index: '/news/jypx', name: '教育培训'},
        //{index: '/news/hyfw', name: '会员服务'},
        //{index: '/news/bzcx', name: '标准查询'},
        {index: '/news/rzfw', name: '认定服务'},
        //{index: '/news/bzkp', name: '标准科普'},
        {index: '/news/bzjd', name: '标准研究'},
        {index: '/about/us', name: '联系我们'},
      ],
    };
  },
  mounted() {
    // 根据不同路由跳转不同页面
    if (this.isMobile) {
      console.log('手机端')
      //this.$router.replace('/mb_index')
    } else {
      console.log('pc端')
      //this.$router.replace('/pc_index')
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    addFavo(url, title) {
      try {
        window.external.addFavorite(url, title);
      } catch (e) {
        try {
          window.sidebar.addPanel(title, url, "");
        } catch (e) {
          this.$message.warning("加入收藏失败，请使用Ctrl+D进行添加");
        }
      }
    },
    setHome(obj, vrl) {
      try {
        obj.style.behavior = 'url(#default#homepage)';
        obj.setHomePage(vrl);
      } catch (e) {
        if (window.netscape) {
          try {
            netscape.security.PrivilegeManager.enablePrivilege("UniversalXPConnect");
          } catch (e) {
            this.$message.warning("此操作被浏览器拒绝！\n请在浏览器地址栏输入“about:config”并回车\n然后将 [signed.applets.codebase_principal_support]的值设置为'true',双击即可。");
          }
          var prefs = Components.classes['@mozilla.org/preferences-service;1'].getService(Components.interfaces.nsIPrefBranch);
          prefs.setCharPref('browser.startup.homepage', vrl);
        } else {
          this.$message.warning({message: "此操作被浏览器拒绝！", duration: 1000});
        }
      }
    },
    cssMainBody() {
      return this.isMobile ? {width: 'auto'} : {};
    }
  }
}

</script>

<style scoped>
#app {
  min-height: 100%;
  /*background:url("assets/images/index/main-bg.png") repeat-x;*/
  background: url("assets/images/index/bg.jpg") center top no-repeat;
}

#main_body {
  width: 1000px;
  position: relative;
  margin: 0 auto;
  /*background-color: #FFF;*/
}

.el-header {
  background-image: url("assets/images/index/h1.png");
}

.logo {
  width: 283px;
  height: 95px;
  position: absolute;
  top: 15px;
  background: url("static/images/home/logo.png") no-repeat top center;
  background-size: contain;
}

.tel, .home {
  position: absolute;
  right: 20px;
  line-height: 30px;
}

.tel {
  font-size: 14px;
  color: #5f5f5f;
  top: 80px;
  background: url(static/images/home/tel.png) left 8px no-repeat;
  padding-left: 20px;
}

.el-menu-demo, .el-content {
  width: 100%;
}

.beian a:visited {
  color: #6e6e6e;
}
</style>
