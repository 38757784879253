/**
 * 新闻模块接口列表
 */

import base from './base'; // 导入接口域名列表
import axios from './http'; // 导入http中创建的axios实例

const news = {
    // 新闻列表
    indexList() {
        return axios.post(`${base.api}/index/list`, {}, {header: {'Content-Type': 'application/json'}});
    },
}

export default news;